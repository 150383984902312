import React from 'react';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import { Box, Flex } from 'rebass';
import slugify from '@sindresorhus/slugify';

import Layout from '../components/Layout';
import Img from 'gatsby-image';
import { cartesian } from '../utils/randomize';
import { CTA } from '../components/ui/CTA';
import { ArticleContainer } from '../components/ui/ArticleContainer';
import Button from '../components/ui/Button';

const ServiceList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  padding-left: 0px;
`;

function AgentCTA({
  title,
  description,
  buttonLabel,
  href,
  image,
  alt,
}: {
  title: string;
  description: React.ReactNode;
  buttonLabel?: string;
  href?: string;
  image?: {
    childImageSharp: object;
  };
  alt?: string;
}) {
  return (
    <Flex>
      <Box mt={'7px'}>
        <CheckIcon />
      </Box>
      <Box ml={[2, 2, 3, 3]}>
        <div className="ml-1 text-xl font-semibold text-gray-700">{title}</div>
        {!!image && <Img {...image.childImageSharp} alt={alt} />}
        <Box my={3} />
        <p>{description}</p>
        {!!href && <CTA href={href}>{buttonLabel}</CTA>}
      </Box>
    </Flex>
  );
}

function CheckIcon({ color = '#55b776' }: { color?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 512 512"
      color={color}
      fill={color}
    >
      <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z" />
    </svg>
  );
}

const QUARTER_LABELS = {
  1: 'premier',
  2: 'deuxième',
  3: 'troisième',
  4: 'dernier',
};

function DataCell({
  bold = false,
  children,
}: {
  bold?: boolean;
  children: React.ReactNode;
}) {
  return <td>{bold ? <b>{children}</b> : children}</td>;
}

function TemplateContent({
  h1,
  firstParagraph,
  secondParagraph,
  thirdAndFourthParagraphs,
  helmet,
  pageContext,
  data,
}) {
  const dangerData = [
    ...(pageContext.department.dangerRank > 3
      ? [
          {
            dep: `Départment ${pageContext.department.code} (${pageContext.department.nom})`,
            cases: `${pageContext.department.casesPerThousand
              .toString()
              .replace('.', ',')} ‰`,
            rank: pageContext.department.dangerRank,
          },
        ]
      : []),
    {
      dep: 'Départment 93 (Seine-Saint-Denis)',
      cases: '84,73 ‰',
      rank: 1,
    },
    {
      dep: 'Départment 13 (Bouches-du-Rhône)',
      cases: '76,51 ‰',
      rank: 2,
    },
    {
      dep: 'Départment 06 (Alpes-Maritimes)',
      cases: '72,62 ‰',
      rank: 3,
    },
  ];

  const theftData = [
    ...(pageContext.department.theftRank > 3
      ? [
          {
            dep: `Départment ${pageContext.department.code} (${pageContext.department.nom})`,
            cases: pageContext.department.theftCount,
            rank: pageContext.department.theftRank,
          },
        ]
      : []),
    {
      dep: 'Départment 59 (Nord)',
      cases: 13380,
      rank: 1,
    },
    {
      dep: 'Départment 13 (Bouches-du-Rhône)',
      cases: 13081,
      rank: 2,
    },
    {
      dep: 'Départment 75 (Paris)',
      cases: 12256,
      rank: 3,
    },
  ];

  const offenseData = [
    ...(pageContext.department.offenseRank > 3
      ? [
          {
            dep: `Départment ${pageContext.department.code} (${pageContext.department.nom})`,
            cases: pageContext.department.offenseCount,
            rank: pageContext.department.offenseRank,
          },
        ]
      : []),
    {
      dep: 'Départment 75 (Paris)',
      cases: 200248,
      rank: 1,
    },
    {
      dep: 'Départment 13 (Bouches-du-Rhône)',
      cases: 91906,
      rank: 2,
    },
    {
      dep: 'Départment 59 (Nord)',
      cases: 85176,
      rank: 3,
    },
  ];

  const plusMinusViolence =
    Number.parseFloat(pageContext.city.crime.violence.replace(',', '.')) > 8.65
      ? 'plus'
      : 'moins';
  const plusMinusTheft =
    Number.parseFloat(pageContext.city.crime.theft.replace(',', '.')) > 30.56
      ? 'plus'
      : 'moins';
  const cityName = pageContext.city.nom;
  const slug = `${slugify(pageContext.city.region.nom)}/${slugify(
    pageContext.department.nom
  )}/${slugify(cityName)}`;

  return (
    <ArticleContainer>
      <article className="mx-auto prose prose-lg text-gray-600 prose-indigo">
        {helmet || ''}
        <h1 id={slugify(h1)}>
          <span className="block mb-4 text-3xl font-extrabold leading-8 tracking-tight text-center text-gray-900 sm:text-4xl">
            {h1}
          </span>
        </h1>
        {firstParagraph}
        <CTA
          href={`https://leadadvisor.fr/devis-gardiennage?utm_campaign=${slug}`}
        >
          Demander un devis gratuit
        </CTA>
        <Box my={4} />
        {secondParagraph}
        <Box my={5} />
        {thirdAndFourthParagraphs}
        <Box my={4}>
          <CTA
            href={`https://leadadvisor.fr/devis-gardiennage?utm_campaign=${slug}`}
          >
            Demander un devis gratuit
          </CTA>
        </Box>
        <h2>
          Informations concernant les entreprises de sécurité et l’insécurité à{' '}
          {pageContext.city.nom}
        </h2>
        <Box my={5} />
        <h3>
          Nombre de sociétés de sécurité dans le département{' '}
          {pageContext.department.code} ({pageContext.department.nom})
        </h3>
        <p>
          Nous avons dénombré{' '}
          <b>
            {pageContext.department.companyCount} sociétés de sécurité privée
          </b>{' '}
          dans le département {pageContext.department.code} (
          {pageContext.department.nom}).
        </p>
        <Box my={4} />
        <h3>Statistiques concernant l’insécurité à {pageContext.city.nom}</h3>
        <p>
          Pour vous donner une idée du niveau d’insécurité à{' '}
          {pageContext.city.nom}, nous avons compilé quelques chiffres relatifs
          à la ville et au département {pageContext.department.code} :
        </p>
        <h4>
          Violences aux personnes recensées en 2014 en nombre de cas pour 1000
          habitants
        </h4>
        <table>
          <tr>
            <DataCell bold>{pageContext.city.nom}</DataCell>
            <DataCell bold>{pageContext.city.crime.violence}</DataCell>
          </tr>
          <tr>
            <DataCell>{pageContext.department.nom}</DataCell>
            <DataCell>{pageContext.department.crime.violence}</DataCell>
          </tr>
          <tr>
            <DataCell>France</DataCell>
            <DataCell>8,65</DataCell>
          </tr>
        </table>
        <h4>
          Vols et dégradations recensés en 2014 en nombre de cas pour 1000
          habitants
        </h4>
        <table>
          <tr>
            <DataCell bold>{pageContext.city.nom}</DataCell>
            <DataCell bold>{pageContext.city.crime.theft}</DataCell>
          </tr>
          <tr>
            <DataCell>{pageContext.department.nom}</DataCell>
            <DataCell>{pageContext.department.crime.theft}</DataCell>
          </tr>
          <tr>
            <DataCell>France</DataCell>
            <DataCell>30,56</DataCell>
          </tr>
        </table>
        <a
          href={`http://www.linternaute.com/actualite/delinquance/${slugify(
            pageContext.city.nom
          )}/ville-${pageContext.city.code}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <i>
            Source :{' '}
            {`http://www.linternaute.com/actualite/delinquance/${slugify(
              pageContext.city.nom
            )}/ville-${pageContext.city.code}`}
          </i>
        </a>
        <Box my={4} />
        En ce qui concerne les violences aux personnes, le nombre de cas à{' '}
        <strong>{pageContext.city.nom}</strong> pour 1000 habitants était{' '}
        <b>{plusMinusViolence}</b> important que la moyenne en France.
        <br />
        Concernant les vols et dégradations, le nombre de cas recensés à{' '}
        <strong>{pageContext.city.nom}</strong> pour 1000 habitants était{' '}
        <b>{plusMinusTheft}</b> important que le nombre moyen de cas pour 1000
        en France.
        <Box my={4} />
        <h3>
          Statistiques concernant l’insécurité dans le{' '}
          {pageContext.department.code}
        </h3>
        <Box my={4} />
        <h4>Nombre de crimes et délits pour 1000 habitants recensés en 2014</h4>
        <table>
          <thead>
            <tr>
              <th>Département</th>
              <th>Nombre de cas pour 1000 habitants</th>
              <th>Rang</th>
            </tr>
          </thead>
          {dangerData.map((line) => (
            <tr key={line.rank}>
              <DataCell bold={line.rank === pageContext.department.dangerRank}>
                {line.dep}
              </DataCell>
              <DataCell bold={line.rank === pageContext.department.dangerRank}>
                {line.cases}
              </DataCell>
              <DataCell bold={line.rank === pageContext.department.dangerRank}>
                {line.rank}
              </DataCell>
            </tr>
          ))}
        </table>
        <i>Source: linternaute.com</i>
        <Box my={4} />
        <h4>Nombre de cambriolages recensés en 2015</h4>
        <table>
          <thead>
            <tr>
              <th>Département</th>
              <th>Nombre de cambriolages</th>
              <th>Rang</th>
            </tr>
          </thead>
          {theftData.map((line) => (
            <tr key={line.rank}>
              <DataCell bold={line.rank === pageContext.department.theftRank}>
                {line.dep}
              </DataCell>
              <DataCell bold={line.rank === pageContext.department.theftRank}>
                {line.cases}
              </DataCell>
              <DataCell bold={line.rank === pageContext.department.theftRank}>
                {line.rank}
              </DataCell>
            </tr>
          ))}
        </table>
        <i>Source: securitasdirect.fr</i>
        <Box my={4} />
        <h4>Nombre de crimes et délits recensés en 2016</h4>
        <table>
          <thead>
            <tr>
              <th>Département</th>
              <th>Nombre de crimes et délits</th>
              <th>Rang</th>
            </tr>
          </thead>
          {offenseData.map((line) => (
            <tr key={line.rank}>
              <DataCell bold={line.rank === pageContext.department.offenseRank}>
                {line.dep}
              </DataCell>
              <DataCell bold={line.rank === pageContext.department.offenseRank}>
                {line.cases}
              </DataCell>
              <DataCell bold={line.rank === pageContext.department.offenseRank}>
                {line.rank}
              </DataCell>
            </tr>
          ))}
        </table>
        <i>Source: securitasdirect.fr</i>
        <Box my={4} />
        <p>
          Par « crimes et délits », nous entendons : les violences, les
          agressions, les vols et dégradations de bien, les cambriolages, les
          trafics de drogues, le proxénétisme, et le port d’armes prohibées. En
          2014, le département {pageContext.department.code} (
          {pageContext.department.nom}) se situait dans le{' '}
          <b>{QUARTER_LABELS[pageContext.department.dangerQuarter]} quart</b>,
          en termes de nombre de cas par habitant.
        </p>
        <Box my={4}>
          <CTA
            href={`https://leadadvisor.fr/devis-gardiennage?utm_campaign=${slug}`}
          >
            Demander un devis gratuit
          </CTA>
        </Box>
      </article>
    </ArticleContainer>
  );
}

const CityTemplate = ({ pageContext, data, ...props }) => {
  const {
    site,
    agentAccueilImage,
    agentSecuriteIncendieImage,
    agentMagasinImage,
    agentSecuriteEvenementImage,
    agentSecuriteImage,
    maitreChienImage,
    rondierIntervenantImage,
  } = data;
  const cityName = pageContext.city.nom;
  const h1 = `Agent de sécurité à ${cityName} (${pageContext.department.code}, ${pageContext.department.nom})`;
  const slug = `${slugify(pageContext.city.region.nom)}/${slugify(
    pageContext.department.nom
  )}/${slugify(cityName)}`;
  const titleVariants = [
    `Trouver un agent de sécurité à ${cityName} - Société de sécurité dans le département ${pageContext.department.code} - Lead Advisor`,
    `Faire appel à un agent de sécurité à ${cityName} - Entreprise de sécurité dans le ${pageContext.department.code} - Lead Advisor`,
    `Services d’agents de sécurité à ${cityName} - Agence de sécurité privée dans votre département ${pageContext.department.code} - Lead Advisor`,
    `Agents de sécurité à ${cityName} (${pageContext.department.code}, ${pageContext.department.nom}) - Sociétés de sécurité dans votre région - Lead Advisor`,
    `Agence de sécurité à ${cityName} - Services d’agents de sécurité - Lead Advisor`,
    `Société de sécurité à ${cityName} - Prestations d’agents de sécurité - Lead Advisor`,
  ];
  const descriptionVariants = [
    `Recevez plusieurs devis gratuits pour des agents de sécurité à ${cityName}. Vous recherchez une agence de sécurité à ${cityName} ? Nous pouvons répondre à vos besoins en agents de sécurité grâce à notre réseau de plus de 300 sociétés de gardiennage.`,
    `Vous avez besoin de prestations d’agents de sécurité afin de sécuriser un évènement, des locaux, ou un domicile à ${cityName} ? Effectuez votre demande de devis gratuits sur Lead Advisor Security en quelques minutes.`,
    `Besoin d’agents de sécurité à ${cityName} ? Demandez vos devis gratuits pour des prestations de sécurité privée à ${cityName} sur Lead Advisor Security en deux minutes. Vous recevrez vos chiffrages très rapidement et gratuitement.`,
    `Vous souhaitez connaître le coût d’un agent de sécurité pour une intervention à ${cityName} ? Recevez vos devis gratuits sur Lead Advisor Security ! Notre réseau comporte plus de 300 agences et nous intervenons sur tout le département ${pageContext.department.code}.`,
    `Besoin d’un prix pour des agents de sécurité à ${cityName} ? Demandez gratuitement votre devis sur Lead Advisor Security en deux minutes par le biais de notre QCM dynamique. Vous recevrez promptement des chiffrages.`,
    `Vous avez besoin d’un devis pour des agents de sécurité à ${cityName} afin d’en connaître le prix ? Recevez gratuitement des chiffrages sur Lead Advisor Security ! En déposant une seule demande via notre rapide QCM, vous recevez plusieurs propositions de la part de prestataires de sécurité privée.`,
  ];
  const agentInfo = [
    {
      title: `Agent de prévention et de sécurité`,
      description: (
        <div>
          L’agent de Prévention et de Sécurité (APS) a pour objectif de protéger
          des personnes, des locaux, ou des biens. Il a en général suivi la
          formation du CQP APS (Certificat de Qualification Professionnelle
          Agent de prévention et de sécurité).
        </div>
      ),
      href: `https://leadadvisor.fr/devis-gardiennage?utm_campaign=${slug}`,
      alt: `Agent de sécurité ${pageContext.city.nom}`,
      buttonLabel: `Demander un devis en ligne pour un agent de sécurité`,
      image: agentSecuriteImage,
    },
    {
      title: `Agent cynophile (maître-chien)`,
      description: (
        <div>
          L’agent cynophile travaille avec un chien (dont il est généralement le
          propriétaire) afin d’assurer la sécurité des clients. L’agent et son
          animal forment un duo particulièrement dissuasif.
        </div>
      ),
      href: `https://leadadvisor.fr/devis-gardiennage?utm_campaign=${slug}`,
      alt: `Agent cynophile`,
      buttonLabel: `Demander un devis en ligne pour un agent cynophile`,
      image: maitreChienImage,
    },
    {
      title: `Agent de sécurité incendie`,
      description: (
        <div>
          L’agent incendie, appelé « agent SSIAP » contrôle et maintient en bon
          fonctionnement le système de sécurité incendie. En cas de problème, il
          déclenche l’alarme, prévient les secours, et procède à l’évacuation.
        </div>
      ),
      href: `https://leadadvisor.fr/devis-gardiennage?utm_campaign=${slug}`,
      alt: `Société de sécurité ${pageContext.city.nom}`,
      buttonLabel: `Demander un devis en ligne pour un agent incendie`,
      image: agentSecuriteIncendieImage,
    },
    {
      title: `Agent d’accueil et de contrôle d’accès`,
      description: (
        <div>
          L’agent d’accueil est placé à l’entrée d’un site et assure la
          réception des visiteurs en les identifiant et en notant leur nom sur
          un registre.
        </div>
      ),
      href: `https://leadadvisor.fr/devis-gardiennage?utm_campaign=${slug}`,
      alt: `Agence de sécurité ${pageContext.city.nom}`,
      buttonLabel: `Demander un devis en ligne pour un agent d’accueil`,
      image: agentAccueilImage,
    },
    {
      title: `Rondier intervenant`,
      description: (
        <div>
          Le rondier intervenant est un agent effectuant des rondes en voiture.
          Il peut réaliser des rondes préventives, et également intervenir sur
          alarme, à la suite d’un appel de la société chargée de la
          télésurveillance.
        </div>
      ),
      href: `https://leadadvisor.fr/devis-gardiennage?utm_campaign=${slug}`,
      alt: `Société de surveillance`,
      buttonLabel: `Demander un devis en ligne pour un rondier intervenant`,
      image: rondierIntervenantImage,
    },
    {
      title: `Agent de prévention et de sécurité évènementiel`,
      description: (
        <div>
          L’agent de sécurité évènementiel est chargé de sécuriser les
          manifestations (professionnelles, festives, ou sportives) rassemblant
          du public.
        </div>
      ),
      href: `https://leadadvisor.fr/devis-gardiennage?utm_campaign=${slug}`,
      alt: `Société de gardiennage`,

      buttonLabel: `Demander un devis en ligne pour un agent de sécurité
évènementiel`,
      image: agentSecuriteEvenementImage,
    },
    {
      title: `Inspecteur de magasin`,
      description: (
        <div>
          L’inspecteur de magasin s’occupe de la surveillance d’une boutique ou
          d’un supermarché. Il peut être placé en première ligne pour assurer
          une présence dissuasive ou bien surveiller le magasin à partir d’un
          ordinateur et de caméras.
        </div>
      ),
      href: `https://leadadvisor.fr/devis-gardiennage?utm_campaign=${slug}`,
      alt: `Entreprise de sécurité`,
      buttonLabel: `Demander un devis en ligne pour un inspecteur de magasin`,
      image: agentMagasinImage,
    },
  ];
  const firstParagraphVariants = [
    <>
      <h2>À la recherche d’une société de sécurité à {cityName} ?</h2>
      <h3>Demandez vos devis gratuits</h3>
      <Box my={4} />
      <p>
        Vous recherchez une agence de sécurité à {cityName} ? Nous pouvons
        répondre à vos besoins en agents de sécurité grâce à notre réseau de
        plus de 300 sociétés de gardiennage. Gagnez en tranquillité en confiant
        votre sécurité à des professionnels agréés. Effectuez votre demande de
        devis gratuitement en quelques minutes !
      </p>
    </>,
    <>
      <h2>Vous avez besoin d’une entreprise de sécurité à {cityName} ?</h2>
      <h3>Recevez vos devis gratuitement</h3>
      <Box my={4} />
      <p>
        Vous avez besoin de prestations de sécurité à {cityName} pour sécuriser
        un évènement, des locaux d’entreprise, ou bien votre domicile ? Déposez
        votre demande de devis gratuits en 2 minutes sur Lead Advisor Security !
        Nous répondrons très promptement à votre demande grâce à notre réseau
        constitué de plus de 300 agences de sécurité privée.
      </p>
    </>,
    <>
      <h2>Besoin des services d’une agence de sécurité à {cityName} ?</h2>
      <h3>Devis gratuits en quelques minutes</h3>
      <Box my={4} />
      <p>
        Vous recherchez une agence de sécurité privée en mesure de vous fournir
        des prestations de sécurité à {cityName} ? Effectuez votre demande de
        devis sur Lead Advisor Security en quelques minutes ! Nous vous
        enverrons des chiffrages gratuitement et très rapidement.
      </p>
    </>,
    <>
      <h2>
        Vous avez besoin des prestations d’une société de sécurité à {cityName}{' '}
        ?
      </h2>
      <h3>Obtenez vos devis gratuitement</h3>
      <Box my={4} />
      <p>
        Vous souhaitez sécuriser votre entreprise, votre domicile, ou bien un
        évènement que vous organisez à {cityName} ? Déposez gratuitement votre
        demande de devis sur Lead Advisor Security ! Vous recevez en retour nos
        propositions très promptement.
      </p>
    </>,
  ];
  const secondParagraphVariants = [
    <>
      <h3>Les différents types d’agent de sécurité</h3>
      <Box my={4} />
      <ServiceList>
        {agentInfo.map((info) => (
          <Box my={4}>
            <AgentCTA
              key={info.title}
              title={info.title}
              description={info.description}
              buttonLabel={info.buttonLabel}
              href={info.href}
              image={info.image}
              alt={info.alt}
            />
          </Box>
        ))}
      </ServiceList>
    </>,
    <>
      <h3>Des agent de sécurité aux compétences adaptées à vos besoins</h3>
      Il existe plusieurs types d’agent de sécurité et plusieurs formations.
      Certains agents ont suivi plusieurs formations et possèdent donc plusieurs
      diplômes. Nos agences sont en mesure de vous conseiller et de mettre à
      votre disposition les agents convenant le mieux à votre situation :
      <ul>
        <li>Agent de Prévention et de Sécurité (APS ou ADS)</li>
        <li>Agent de sécurité événementiel</li>
        <li>Maître chien (agent cynophile)</li>
        <li>Agent d’accueil et de contrôle d’accès</li>
        <li>Rondier</li>
        <li>Agent de sécurité incendie (SSIAP1, SSIAP2, ou SSIAP3)</li>
        <li>Inspecteur de magasin</li>
      </ul>
      Un <strong>Agent de Prévention et de Sécurité</strong> (APS) bénéficie
      d’une certaine polyvalence et pourra accomplir diverses missions.
      <br />
      Pour les manifestations et évènements rassemblant un large public, un (ou
      plusieurs) <strong>agent de sécurité événementiel</strong> est recommandé.
      <br />
      Pour la surveillance de site sensible, un <strong>
        agent cynophile
      </strong>{' '}
      conviendra.
      <br />
      Pour la réception et la vérification de l’identité des visiteurs (et leur
      éventuel refus), un <strong>agent d’accueil</strong> est tout indiqué.
      <br />
      Pour effectuer des rondes de sécurité préventives en véhicules sur un
      grand site, vous devriez faire appel à des{' '}
      <strong>rondiers intervenants</strong>.
      <br />
      Afin que la sécurité incendie soit assurée (vérification du bon
      fonctionnement du matériel et des voies d’évacuation), faites appels aux
      services d’<strong>agents de sécurité incendie SSIAP</strong>.
      <br />
      Enfin, pour prévenir la démarque inconnue et prévenir tout débordement
      dans un commerce (boutique, petites et moyennes surfaces, supérettes,
      etc.), les services d’un <strong>inspecteur de magasin</strong> sont à
      préconiser.
      <br />
      Pour en savoir plus sur les différents types d’agent de sécurité, vous
      pouvez consulter notre{' '}
      <a
        href="https://www.leadadvisor.fr/articles/agent-de-securite-la-fiche-metier"
        target="_blank"
        rel="noopener noreferrer"
      >
        fiche métier sur les agents de sécurité
      </a>
      .
    </>,
    <>
      <h3>Différents agents de sécurité pour différents besoins</h3>
      <p>
        Les agents de sécurité n’ont pas tous suivi les mêmes formations et ne
        disposent pas tous des mêmes compétences. En fonction de vos besoins, il
        sera possible de vous fournir le dispositif de sécurité le plus adapté,
        composé d’agents de sécurité correspondant parfaitement à votre
        situation.
      </p>
      <Box my={4} />
      <ServiceList>
        {[
          {
            title: 'Agent de Prévention et de Sécurité',
            description:
              'Il s’agit d’un agent polyvalent, généralement détenteur d’un Certificat de Qualification Professionnelle d’Agent de Prévention et de Sécurité (CQP APS), capable d’accomplir diverses missions : faire du filtrage, protéger des personnes, contrôler les accès à un lieu, surveiller un commerce, une résidence, ou des bureaux, etc.',
          },
          {
            title: 'Agent de sécurité événementiel',
            description:
              'Un Agent de prévention et de sécurité évènementiel (APSE) est chargé d’assurer la sécurité d’une manifestation (conférence, exposition, salon, inauguration, match de football ou autre sport…) ou d’un événement privé (mariage, anniversaire, soirée…) rassemblant de nombreux visiteurs. Il peut contrôler les billets, s’occuper des barrières, et effectuer des palpations si l’agrément nécessaire est détenu et que les conditions d’autorisation sont réunies.',
          },
          {
            title: 'Agent cynophile',
            description:
              'L’agent cynophile, également appelé maître-chien, forme un binôme très efficace et dissuasif avec son chien. Ce tandem pourra par exemple assurer la sécurité d’un site sensible, exposé aux dégradations, aux squats, ou aux cambriolages, telle qu’une résidence, un chantier, un entrepôt, ou un commerce, de jour ou de nuit.',
          },
          {
            title: 'Rondier',
            description:
              'Un rondier intervenant réalise des rondes préventives en véhicule, et effectue également des levées de doute sur demande des centres de télésurveillance, lorsqu’un système d’alerte se déclenche et qu’il est nécessaire de se rendre sur site pour confirmer ou infirmer une intrusion dans un lieu.',
          },
          {
            title: 'Agent de sécurité incendie',
            description:
              'L’agent de sécurité incendie SSIAP (Service de Sécurité Incendie et Assistance aux Personnes) s’occupe de vérifier et de maintenir le bon fonctionnement du matériel incendie. Il est employé dans les immeubles de grande hauteur (IGH) et les établissements recevant du public (ERP). Beaucoup d’agent de sécurité possèdent un diplôme SSIAP1, ce qui leur permet d’assurer la sécurité incendie d’un lieu en plus de la sécurité physique.',
          },
          {
            title: 'Agent d’accueil et de contrôle d’accès',
            description:
              'Un agent d’accueil est un véritable agent de sécurité qui s’occupe de la réception et de la vérification de l’identité des visiteurs à l’entrée d’un site. Ce n’est pas une hôtesse d’accueil (les sociétés de sécurité privée ne sont pas habilitées à fournir les services d’hôtesses d’accueil).',
          },
          {
            title: 'Inspecteur de magasin',
            description:
              'Un inspecteur de magasin se charge de prévenir les vols et d’assurer la sécurité du commerce qu’il surveille. Il peut se tenir à l’entrée de la boutique, circuler dans les rayons, ou bien rester dans l’arrière boutique pour observer l’ensemble du magasin par le biais de caméras.',
          },
        ].map((info) => (
          <Box my={4}>
            <AgentCTA
              key={info.title}
              title={info.title}
              description={info.description}
            />
          </Box>
        ))}
      </ServiceList>
    </>,
  ];
  const thirdAndFourthParagraphsVariants = [
    <>
      <h3>Qualité des prestations</h3>
      <Box my={4} />
      <p>
        Recevez des propositions de la part de société de sécurité privées
        agréés CNAPS (Conseil National des Activités Privées de Sécurité). Vous
        pourrez consulter leur profil, leurs certifications, et leurs
        recommandations. Dans un secteur dont les prestations sont très
        inégales, il est très important de bien choisir votre prestataire, afin
        de confier vos missions à une société fiable et sérieuse.
      </p>
      <h3>Réactivité</h3>
      <Box my={4} />
      <p>
        Votre demande de devis sera étudiée sous les plus brefs délais. En
        moyenne, vous recevrez un chiffrage sous une heure ouvrée. Grâce à notre
        rapide QCM de deux minutes vous permettant d’indiquer le type et le
        nombre d’agents de sécurité dont vous avez besoin, les horaires et les
        dates de la prestation, ainsi que son contexte, vous pourrez recevoir
        très vite des devis précis en toute simplicité.
      </p>
    </>,
    <>
      <h3>Rapidité</h3>
      <Box my={4} />
      <p>
        Le dépôt de votre demande de devis s’effectue très simplement et très
        rapidement, par le biais d’un QCM dynamique, qui s’adapte en fonction de
        vos réponses et qui se remplit en deux minutes environ. Puis, grâce à
        notre réseau composé de plus de 300 agences de sécurité désireuses de se
        développer commercialement, vous recevrez très rapidement de multiples
        propositions, qui vous permettront de comparer les services proposés. Ne
        perdez plus de temps à contacter plusieurs agences qui ne vous
        répondront pas ou qui ne seront pas en mesure de vous fournir les
        prestations souhaitées !
      </p>
      <h3>Fiabilité</h3>
      <Box my={4} />
      <p>
        Sur la plateforme Lead Advisor Security, vous pouvez consulter les
        profils et les certifications des prestataires, puis échanger avec ces
        derniers afin d’évaluer leur professionnalisme et d’établir une relation
        de confiance. De plus, Lead Advisor Security délivre un label
        “Partenaire certifié Lead Advisor” aux agences de sécurité dont le
        dossier administratif a soigneusement été examiné. Pour être certifiée
        par Lead Advisor, la société de sécurité doit notamment être
        immatriculée, détenir les agrément CNAPS nécessaires pour exercer, et
        être à jour dans le paiement de ses cotisations sociales et de ses
        impôts.
      </p>
    </>,
    <>
      <h3>Réactivité de nos services</h3>
      <Box my={4} />
      <p>
        Lorsque vous déposez un appel d’offres, c’est-à-dire une demande de
        devis, celle-ci est immédiatement examinée et validée par nos équipes.
        Ensuite, elle est automatiquement transmises à des centaines d’agences
        de sécurité privée partenaires très motivées pour trouver des vacations
        pour leurs agents. Ainsi, il n’est pas rare de recevoir plusieurs
        propositions en nettement moins d’une heure, ce qui vous permettra de
        budgétiser vos besoins très rapidement, puis d’effectuer votre choix !
      </p>
      <h3>Qualité de service</h3>
      <Box my={4} />
      <p>
        Lead Advisor Security vous permet de consulter les profils complets des
        sociétés de sécurité privée qui répondent à vos demandes de devis. Un
        bon profil, des certifications, et des échanges professionnels et
        convaincants vous permettront d’établir une relation de confiance avec
        les prestataires et d’effectuer votre choix en tout sérénité. De plus
        Lead Advisor Security attribue deux labels aux entreprises de sécurité
        privée ayant rempli les critères nécessaires : “Partenaire Premium” et
        “Partenaire certifié”. “Partenaire Premium” est réservé aux partenaires
        privilégiés de Lead Advisor Security. “Partenaire certifié” est attribué
        aux agences dont le dossier administratif a été examiné et validé par
        Lead Advisor. Il est possible de cumuler les deux labels.
      </p>
    </>,
  ];

  const permutations = cartesian(
    firstParagraphVariants,
    secondParagraphVariants,
    thirdAndFourthParagraphsVariants
  );

  // const permutationsIndices = cartesian(
  //   firstParagraphVariants.map((_, i) => i + 1),
  //   secondParagraphVariants.map((_, i) => i + 1),
  //   thirdAndFourthParagraphsVariants.map((_, i) => i + 1)
  // );
  // console.log({
  //   cityName: pageContext.city.nom,
  //   combinaison:
  //     permutationsIndices[
  //       pageContext.cityRandomIndex % permutationsIndices.length
  //     ],
  //   cityRandomIndex: pageContext.cityRandomIndex,
  // });

  const title =
    titleVariants[pageContext.cityRandomIndex % titleVariants.length];
  const description =
    descriptionVariants[
      pageContext.cityRandomIndex % descriptionVariants.length
    ];

  const [
    firstParagraph,
    secondParagraph,
    thirdAndFourthParagraphs,
  ] = permutations[pageContext.cityRandomIndex % permutations.length];

  return (
    <Layout
      sidebarContent={null}
      rightsidebarContent={
        <div className="flex justify-center py-2 mt-12 shadow-md xl:shadow-none bg-indigo-50 xl:bg-transparent xl:mr-8 xl:p-0">
          <a
            href={`https://leadadvisor.fr/devis-gardiennage?utm_campaign=${slug}`}
          >
            <Button variant="secondary" className="xl:animate-pump">
              Demander un devis gratuit
            </Button>
          </a>
        </div>
      }
    >
      <TemplateContent
        h1={h1}
        firstParagraph={firstParagraph}
        secondParagraph={secondParagraph}
        thirdAndFourthParagraphs={thirdAndFourthParagraphs}
        helmet={
          <Helmet titleTemplate="%s">
            <title>{title}</title>
            <link
              rel="canonical"
              href={`${site.siteMetadata.siteUrl}/articles/agent-de-securite/${slug}`}
            />
            <meta name="description" content={description} />
            <meta property="og:title" content={title} />
            <meta
              property="og:url"
              content={`${site.siteMetadata.siteUrl}/${slug}`}
            />
            <meta name="author" content="David Khoy de Lead Advisor" />

            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:creator" content="@lead_advisor" />
            <meta name="twitter:title" content={title} />
            <meta
              name="twitter:url"
              content={`${site.siteMetadata.siteUrl}/${slug}`}
            />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image" content="/img/og-image.jpg" />

            <script type="application/ld+json">
              {`
 {
  "@context": "http://schema.org",
  "@type": "BreadcrumbList",
  "itemListElement": [{
    "@type": "ListItem",
    "position": 1,
    "item": "${site.siteMetadata.siteUrl}/articles/agent-de-securite",
    "name": "Agent de sécurité"
  },{
    "@type": "ListItem",
    "position": 2,
    "item": "${site.siteMetadata.siteUrl}/articles/agent-de-securite/${slugify(
                pageContext.department.region.nom
              )}",
    "name": "${pageContext.department.region.nom}"
  },{
    "@type": "ListItem",
    "position": 3,
     "item": "${site.siteMetadata.siteUrl}/articles/agent-de-securite/${slugify(
                pageContext.department.region.nom
              )}/${slugify(pageContext.department.nom)}",
    "name": "${pageContext.department.nom}"
  },{
    "@type": "ListItem",
    "position": 4,
     "item": "${site.siteMetadata.siteUrl}/articles/agent-de-securite/${slugify(
                pageContext.city.region.nom
              )}/${slugify(pageContext.department.nom)}/${slugify(
                pageContext.city.nom
              )}",
    "name": "${pageContext.city.nom}"
  }]
}
`}
            </script>
          </Helmet>
        }
        pageContext={pageContext}
        data={data}
      />
    </Layout>
  );
};

export const pageQuery = graphql`
  query citySiteMetadata {
    site {
      siteMetadata {
        siteUrl
      }
    }
    agentAccueilImage: file(relativePath: { eq: "agent-accueil.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 650) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    agentSecuriteIncendieImage: file(
      relativePath: { eq: "agent-de-securite-incendie.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 650, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    agentMagasinImage: file(relativePath: { eq: "agent-magasin.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 650, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    agentSecuriteEvenementImage: file(
      relativePath: { eq: "agent-securite-evenement.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 650, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    agentSecuriteImage: file(relativePath: { eq: "agent-securite.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 650, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    maitreChienImage: file(relativePath: { eq: "maitre-chien.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 650, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    rondierIntervenantImage: file(
      relativePath: { eq: "rondier-intervenant.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 650, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default CityTemplate;
